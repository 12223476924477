import { templateConstants } from '../constants';

const initialState = {
  templates: {
    content: [],
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: [],
    totalElements: 0,
    totalPages: 1,
  },
};
export function template(state = initialState, action) {
  switch (action.type) {
    case templateConstants.TEMPLATES_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case templateConstants.TEMPLATES_GETALL_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        url: action.url,
        loading: false,
      };
    case templateConstants.TEMPLATES_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
