import {starConstants} from '../constants';
const initialState = {
  star: {
    list: {
      content: [],
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      size: 0,
      sort: [],
      totalElements: 0,
      totalPages: 1,
      loading: null,
      taggedStars: [],
    },
    star: {
      data: {
        id: '',
        starGroupingDTOs: [],
        categoryDTOs: [],
        bannerURL: '',
        profileImageUrl: '',
        videoChannels: [],
      },
      loading: null,
    },
    link_data_entry_user: {
      starId: null,
      loading: null,
    },
    alias: {
      list: {
        data: [],
        loading: null,
      },
      create: {
        loading: null,
      },
      delete_alias: {
        loading: null,
        id: '',
      },
      update_alias: {
        loading: null,
      },
    },
    categories: {
      list: {
        data: [],
        loading: null,
      },
    },

    audio: {
      list: {
        data: {
          content: [],
          first: true,
          last: true,
          number: 0,
          numberOfElements: 0,
          size: 0,
          sort: [],
          totalElements: 0,
          totalPages: 1,
          loading: true,
        },
        loading: null,
      },
      stars: {
        create: {
          loading: null,
        },
        list: {
          data: [],
          loading: null,
        },
        delete: {
          loading: null,
        },
      },
      create: {
        loading: null,
      },
      audio_delete: {
        loading: false,
      },
      audio_upload: {
        loading: null,
      },
      templates: {
        data: {
          content: [],
          first: true,
          last: true,
          number: 0,
          numberOfElements: 0,
          size: 0,
          sort: [],
          totalElements: 0,
          totalPages: 1,
          loading: true,
        },
        loading: null,
      },
      languages: {
        data: [],
        loading: null,
      },
      delete_delete: {
        loading: false,
      },
    },
    external_ids: {
      list: {
        data: {},
        loading: null,
      },
      create: {
        loading: null,
      },
    },
    movies: {
      list: {
        data: [],
        loading: null,
      },
    },
    event_types: {
      list: {
        data: [],
        loading: null,
      },
      event_create: {
        loading: null,
      },
    },
    perk_types: {
      list: {
        data: [],
        loading: null,
      },
    },

    channels: {
      create: {
        loading: null,
      },
      channel_delete: {
        loading: null,
      },
    },
    news: {
      list: {
        data: {
          content: [],
          first: true,
          last: true,
          number: 0,
          numberOfElements: 0,
          size: 0,
          sort: [],
          totalElements: 0,
          totalPages: 1,
        },
        loading: null,
      },
      create: {
        loading: null,
      },
      status: '',
      update_status: {
        loading: null,
      },
      delete_news: {
        loading: null,
      },
      alreadyexists_news: {
        data: {
          id: null,
        },
      },
    },
    gallery_edit: {
      loading: false,
      updated: false,
    },
    audio_edit: {
      loading: false,
      updated: false,
    },
    video_edit: {
      loading: false,
      updated: false,
    },
    event_edit: {
      loading: false,
      updated: false,
    },
    grouping: {
      create: {
        loading: null,
      },
      delete_group: {
        id: '',
        loading: null,
      },
    },
    video_details: {
      data: {
        id: null,
        tagStarsIds: [],
        stars: [],
        type: '',
        url: '',
        source: '',
        videoThumbnail: '',
        title: '',
      },
      loading: false,
    },
    show_template_link_modal: {
      show: null,
      drawerType: '',
      id: null,
    },
    show_add_cosmos_video_modal: {
      show: null,
      drawerType: '',
      id: null,
    },
    link_video_to_movie: {
      templateId: null,
      loading: true,
    },
    show_gallery_template_link_modal: {
      show: null,
      drawerType: '',
      id: null,
    },
    link_movie_to_gallery: {
      templateId: null,
      loading: true,
    },
    subscription: {
      plans: {
        data: [],
        loading: null,
      },
      create: {
        loading: null,
      },

      plan_delete: {
        loading: null,
      },
    },
    bank_details: {
      loading: false,
      accountNo: '',
      bankName: '',
      beneficiaryName: '',
      branch: '',
      contactNo: '',
      email: '',
      ifscCode: '',
      name: '',
    },
    kyc_details: {
      loading: false,
      docs: [],
    },
    reset_password: {
      status: false,
      loading: false,
    },
    linkedUserInfo: {
      id: null,
      name: '',
      username: '',
      profileImageUrl: '',
      following: false,
      statusUpdateTimestamp: null,
      loading: false,
    },
  },
};

export function star(state = initialState, action) {
  switch (action.type) {
    case starConstants.STARS_GETALL_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            content: [],
            first: true,
            last: true,
            number: 0,
            numberOfElements: 0,
            size: 0,
            sort: [],
            totalElements: 0,
            totalPages: 1,
            loading: true,
          },
        },
      };
    case starConstants.STARS_GETALL_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            ...action.stars,
            loading: false,
          },
        },
        stars: action.stars,
        url: action.url,
      };
    case starConstants.STARS_GETALL_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            loading: false,
          },
        },
      };
    case starConstants.STARS_CLEAR:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            content: [],
            first: true,
            last: true,
            number: 0,
            numberOfElements: 0,
            size: 0,
            sort: [],
            totalElements: 0,
            totalPages: 1,
            loading: false,
          },
        },
      };
    case starConstants.STAR_DELETE_REQUEST:
      return {
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            loading: true,
          },
        },
      };
    case starConstants.STAR_DELETE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            content: state.star.list.content.filter(
              (star) => star.id !== action.star.id,
            ),
            totalElements: state.star.list.totalElements - 1,
            loading: false,
          },
        },
      };
    case starConstants.STAR_DELETE_FAILURE:
      return {
        ...state,
      };
    case starConstants.STAR_GET_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: true,
          },
        },
      };
    case starConstants.STAR_GET_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            data: action.starDetails,
            loading: false,
          },
        },
        starDetails: action.starDetails,
      };
    case starConstants.STAR_GET_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: false,
          },
        },
      };
    case starConstants.STAR_CATEGORIES_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          categories: {
            ...state.star.categories,
            list: {
              ...state.star.categories.list,
              loading: true,
            },
          },
        },
        categoryList: action.categoryList,
        categoryLoading: false,
      };
    case starConstants.STAR_CATEGORIES_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          categories: {
            ...state.star.categories,
            list: {data: action.categoryList, loading: false},
          },
        },
        categoryList: action.categoryList,
        categoryLoading: false,
      };

    case starConstants.STAR_CATEGORIES_FAILURE:
      return {
        ...state,
        categoryList: {},
        categoryLoading: false,
      };
    case starConstants.STAR_ADD_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: true,
          },
        },
      };
    case starConstants.STAR_ADD_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            data: action.star,
            loading: false,
          },
        },
      };
    case starConstants.STAR_ADD_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: false,
          },
        },
      };
    case starConstants.STAR_UPDATEPROFILE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: true,
          },
        },
      };
    case starConstants.STAR_UPDATEPROFILE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            data: action.star,
            loading: false,
          },
        },
      };
    case starConstants.STAR_UPDATEPROFILE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: false,
          },
        },
      };
    case starConstants.STAR_GETMOVIES_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          movies: {
            ...state.star.movies,
            list: {
              ...state.star.movies.list,
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GETMOVIES_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          movies: {
            ...state.star.movies,
            list: {
              ...state.star.movies.list,
              data: action.movies,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GETMOVIES_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          movies: {
            ...state.star.movies,
            list: {
              ...state.star.movies.list,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_UPLOAD_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: true,
          },
        },
      };
    case starConstants.STAR_UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            data: {
              ...state.star.star.data,
              profileImageUrl: action.profileImageUrl + new Date().getTime(),
            },
            loading: false,
          },
        },
        starDetails: {
          ...state.starDetails,
          loading: false,
        },
      };
    case starConstants.STAR_UPLOAD_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: false,
          },
        },
        starDetails: {
          ...state.starDetails,
          loading: false,
        },
      };
    case starConstants.STAR_UPLOAD_BANNER_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: true,
          },
        },
      };
    case starConstants.STAR_UPLOAD_BANNER_IMAGE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            data: {
              ...state.star.star.data,
              bannerURL: action.bannerURL + new Date().getTime(),
            },
            loading: false,
          },
        },
        starDetails: {
          ...state.starDetails,
          loading: false,
        },
      };
    case starConstants.STAR_UPLOAD_BANNER_IMAGE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: false,
          },
        },
        starDetails: {
          ...state.starDetails,
          loading: false,
        },
      };
    case starConstants.STAR_DELETE_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: true,
          },
        },
      };
    case starConstants.STAR_DELETE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            data: action.starDetails,
            loading: false,
          },
        },
        starDetails: action.starDetails,
      };
    case starConstants.STAR_DELETE_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: false,
          },
        },
      };
    case starConstants.STAR_DELETE_BANNER_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: true,
          },
        },
      };
    case starConstants.STAR_DELETE_BANNER_IMAGE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            data: action.starDetails,
            loading: false,
          },
        },
      };
    case starConstants.STAR_DELETE_BANNER_IMAGE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            loading: false,
          },
        },
      };
    case starConstants.STAR_DELETE_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          gallery: {
            ...state.star.gallery,
            gallery_delete: {
              loading: true,
            },
          },
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_DELETE_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          gallery: {
            ...state.star.gallery,
            list: {
              ...state.star.gallery.list,
              data: {
                ...state.star.gallery.list.data,
                content: state.star.gallery.list.data.content.filter(
                  (gallery) => gallery.id !== action.img,
                ),
                number:
                  state.star.gallery.list.data.numberOfElements - 1 === 0
                    ? Math.max(0, state.star.gallery.list.data.number - 2)
                    : state.star.gallery.list.data.number,
                numberOfElements:
                  state.star.gallery.list.data.numberOfElements - 1,
                totalElements: state.star.gallery.list.data.totalElements - 1,
                totalPages: Math.ceil(
                  state.star.gallery.list.data.totalElements -
                    1 / state.star.gallery.list.data.size,
                ),
              },
            },
            gallery_delete: {
              loading: false,
            },
          },
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.STAR_DELETE_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          gallery: {
            ...state.star.gallery,
            gallery_delete: {
              loading: false,
            },
          },
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: false,
            updated: false,
          },
        },
      };
    case starConstants.STAR_GET_TAGGED_STARS_SUCCESS:
      return {
        ...state,
        // stars: {
        //   content: action.stars,
        // },
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            taggedStars: action.stars,
          },
        },
      };

    case starConstants.STAR_DELETE_TAGGED_STARS_SUCCESS:
      return {
        ...state,
        // stars: {
        //   content: state.stars.content.filter(
        //     (star) => !action.stars.includes(star.id),
        //   ),
        // },
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            taggedStars: state.star.list.taggedStars.filter(
              (star) => !action.stars.includes(star.id),
            ),
          },
        },
      };
    case starConstants.STAR_DELETE_VIDEO_CHANNEL_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          channels: {
            ...state.star.channels,
            create: {
              loading: null,
            },
            channel_delete: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_DELETE_VIDEO_CHANNEL_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            data: action.star,
          },
          channels: {
            ...state.star.channels,
            channel_delete: {
              loading: false,
            },
          },
        },
      };

    case starConstants.STAR_DELETE_VIDEO_CHANNEL_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          channels: {
            ...state.star.channels,
            channel_delete: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_ADD_VIDEO_CHANNEL_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          channels: {
            ...state.star.channels,
            create: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_ADD_VIDEO_CHANNEL_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            data: action.star,
          },
          channels: {
            ...state.star.channels,
            create: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_ADD_VIDEO_CHANNEL_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          channels: {
            ...state.star.channels,
            create: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_UPLOAD_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: true,
            udpated: false,
          },
        },
      };
    case starConstants.STAR_UPLOAD_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        starDetails: {
          ...state.starDetails,
          loading: false,
        },
        star: {
          ...state.star,
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.STAR_UPLOAD_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        starDetails: {
          ...state.starDetails,
          loading: false,
        },
        star: {
          ...state.star,
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: false,
            updated: false,
          },
        },
      };
    case starConstants.STAR_VERIFY_REQUEST:
      return {
        ...state,
      };
    case starConstants.STAR_VERIFY_SUCCESS:
      return {
        ...state,
        starDetails: action.star,
      };

    case starConstants.STAR_VERIFY_FAILURE:
      return {
        ...state,
      };
    case starConstants.STAR_MARKFORDELETE_SUCCESS:
      return {
        ...state,
        starDetails: {
          ...state.starDetails,
          deleted: action.markForDelete,
        },
      };

    case starConstants.STAR_MARKFORDELETE_FAILURE:
      return {
        ...state,
      };
    case starConstants.STAR_MARKFORUNDELETE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            loading: true,
          },
        },
        starDetails: {
          ...state.starDetails,
          deleted: action.markForDelete,
        },
      };
    case starConstants.STAR_MARKFORUNDELETE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            content: state.star.list.content.filter(
              (star) => star.id !== action.starId,
            ),
            totalElements: state.star.list.totalElements - 1,
            loading: false,
          },
        },
        starDetails: {
          ...state.starDetails,
          deleted: action.markForDelete,
        },
      };

    case starConstants.STAR_MARKFORUNDELETE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            loading: false,
          },
        },
      };
    case starConstants.STAR_DOWNLOAD_IMAGE_FROM_URL_REQUEST:
      return {
        ...state,
      };
    case starConstants.STAR_DOWNLOAD_IMAGE_FROM_URL_SUCCESS:
      return {
        ...state,
        imageBase64: {
          image: action.image,
          time: +new Date().getTime(),
          loading: false,
        },
      };
    case starConstants.STAR_DOWNLOAD_IMAGE_FROM_URL_FAILURE:
      return {
        ...state,
        imageBase64: {
          ...state.imageBase64,
          loading: false,
        },
      };

    case starConstants.STAR_GALLERY_MODAL_OPEN:
      return {
        ...state,
        gallerymodal: action.gallerymodal,
        galleryMediaLink: action.mediaLink,
      };
    case starConstants.STAR_GALLERY_MODAL_CLOSE:
      return {
        ...state,
        gallerymodal: action.gallerymodal,
        galleryMediaLink: null,
      };
    case starConstants.STAR_TAG_MODAL_CLOSE:
      return {
        ...state,
        stars: {
          content: action.stars,
        },
      };
    case starConstants.STAR_GROUP_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            data: {
              ...state.star.star.data,
              ...action.star,
            },
            loading: false,
          },
        },
      };
    case starConstants.STAR_GROUP_FAILURE:
      return {
        ...state,
      };
    case starConstants.STAR_GROUPED_DELETE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          grouping: {
            ...state.star.grouping,
            delete_group: {
              id: action.id,
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GROUPED_DELETE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          star: {
            ...state.star.star,
            data: {
              ...state.star.star.data,
              starGroupingDTOs: state.star.star.data.starGroupingDTOs.filter(
                (star) => star.starCompacttDTO.id !== action.id,
              ),
            },
          },
          grouping: {
            ...state.star.grouping,
            delete_group: {
              ...state.star.grouping.delete_group,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GROUPED_DELETE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          grouping: {
            ...state.star.grouping,
            delete_group: {
              ...state.star.grouping.delete_group,
              loading: false,
            },
          },
        },
      };

    case starConstants.STARS_GETALL_BYSEARCH_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            content: [],
            first: true,
            last: true,
            number: 0,
            numberOfElements: 0,
            size: 0,
            sort: [],
            totalElements: 0,
            totalPages: 1,
            loading: true,
          },
        },
      };
    case starConstants.STARS_GETALL_BYSEARCH_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...action.stars,
            loading: false,
          },
        },
        stars: action.stars,
        url: action.url,
        pageable: action.pageable,
        isAddEnabled: false,
      };
    case starConstants.STARS_GETALL_BYSEARCH_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            loading: true,
          },
        },
      };
    case starConstants.STAR_SAVE_AND_GO:
      return {
        ...state,
        isAddEnabled: action.isEnabled,
      };
    case starConstants.STAR_GETEXTENAL_ID_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          external_ids: {
            ...state.star.external_ids,
            list: {
              ...state.star.external_ids.list,
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GETEXTENAL_ID_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          external_ids: {
            ...state.star.external_ids,
            list: {
              ...state.star.external_ids.list,
              data: action.ids,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GETEXTENAL_ID_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          external_ids: {
            ...state.star.external_ids,
            list: {
              ...state.star.external_ids.list,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_NEWS_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            list: {
              ...state.star.news.list,
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GET_NEWS_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            list: {
              ...state.star.news.list,
              data: {...action.news},
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_NEWS_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            list: {
              ...state.star.news.list,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_ADD_VIDEO_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            create: {
              loading: true,
            },
            tiktok_get: {
              loading: false,
            },
            insta_get: {
              loading: false,
            },
          },
          video_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_ADD_VIDEO_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            list: {
              ...state.star.videos.list,
              data: {
                ...state.star.videos.list.data,
                content: [action.video, ...state.star.videos.list.data.content],
              },
            },
            create: {
              loading: false,
            },
          },
          video_edit: {
            loading: false,
            updated: true,
          },
          show_add_cosmos_video_modal: {
            show: false,
            drawerType: '',
            id: '',
          },
        },
      };

    case starConstants.STAR_ADD_VIDEO_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            create: {
              loading: false,
            },
            existing_video: {
              data: action.data,
            },
          },
          video_edit: {
            loading: false,
            updated: false,
          },
        },
      };
    case starConstants.STAR_UPDATE_VIDEOS_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            list: {
              ...state.star.videos.list,
              loading: true,
            },
          },
          video_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_UPDATE_VIDEOS_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            list: {
              ...state.star.videos.list,
              data: {
                ...state.star.videos.list.data,

                content: state.star.videos.list.data.content.map((video) =>
                  video.id === action.id ? action.video : video,
                ),
              },
              loading: false,
            },
          },
          video_edit: {
            loading: false,
            updated: true,
          },
        },
        isVideosLoaded: true,
      };
    case starConstants.STAR_UPDATE_VIDEOS_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            list: {
              ...state.star.videos.list,
              loading: false,
            },
          },
          video_edit: {
            loading: false,
            updated: false,
          },
        },
      };
    case starConstants.STAR_DELETE_VIDEO_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            video_delete: {
              loading: true,
            },
          },
          video_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            list: {
              ...state.star.videos.list,
              data: {
                ...state.star.videos.list.data,
                content: state.star.videos.list.data.content.filter(
                  (video) => video.id !== action.id,
                ),
                numberOfElements:
                  state.star.videos.list.data.numberOfElements - 1,
                size: state.star.videos.list.data.size - 1,
                totalElements: state.star.videos.list.data.totalElements - 1,
              },
            },
            video_delete: {
              loading: false,
            },
          },
          video_edit: {
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.STAR_DELETE_VIDEO_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            video_delete: {
              loading: true,
            },
          },
          video_edit: {
            loading: false,
            updated: false,
          },
        },
      };

    case starConstants.VIDEO_GET_TAGGED_STARS_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            taggedStars: action.stars,
          },
        },
      };
    case starConstants.VIDEO_GET_TAGGED_STARS_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            taggedStars: [],
          },
        },
      };
    case starConstants.REMOVE_STARS_FROM_VIDEOS_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          list: {
            ...state.star.list,
            taggedStars: state.star.list.taggedStars.filter(
              (star) => !action.stars.includes(star.id),
            ),
          },
        },
      };
    case starConstants.REMOVE_STARS_FROM_VIDEOS_FAILURE:
      return {
        ...state,
      };
    case starConstants.STAR_SEARCH_BY_ID_REQUEST:
      return {
        ...state,
      };
    case starConstants.STAR_SEARCH_BY_ID_SUCCESS:
      return {
        ...state,
        stars: {
          content: [action.star],
        },
      };
    case starConstants.STAR_SEARCH_BY_ID_FAILURE:
      return {
        ...state,
        stars: {
          content: [],
        },
      };
    case starConstants.STAR_ADD_EXTERNALID_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          external_ids: {
            ...state.star.external_ids,
            create: {loading: true},
          },
        },
      };
    case starConstants.STAR_ADD_EXTERNALID_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          external_ids: {
            ...state.star.external_ids,
            list: {
              ...state.star.external_ids.list,
              data: {
                ...action.externalId,
                ...state.star.external_ids.list.data,
              },
            },
            create: {loading: false},
          },
        },
      };

    case starConstants.STAR_ADD_EXTERNALID_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          external_ids: {
            ...state.star.external_ids,
            create: {loading: false},
          },
        },
      };
    case starConstants.STAR_GET_TIKTOK_MEDIA_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            tiktok_get: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GET_TIKTOK_MEDIA_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            tiktok_get: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_TIKTOK_MEDIA_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            tiktok_get: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_INSTAGRAM_MEDIA_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            insta_get: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GET_INSTAGRAM_MEDIA_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            insta_get: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_INSTAGRAM_MEDIA_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            insta_get: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_FACEBOOK_MEDIA_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            fb_get: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GET_FACEBOOK_MEDIA_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            fb_get: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_FACEBOOK_MEDIA_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            fb_get: {
              loading: false,
            },
          },
        },
      };
    case starConstants.MISTAG_UNLINK_STAR_REQUEST:
      return {
        ...state,
      };
    case starConstants.MISTAG_UNLINK_STAR_SUCCESS:
      return {
        ...state,
        stars: {
          ...state.stars,
          content: state.stars.content.filter((star) => star.id !== action.id),
        },
      };
    case starConstants.MISTAG_UNLINK_STAR_FAILURE:
      return {
        ...state,
      };

    case starConstants.GET_VIDEO_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          video_details: {
            ...state.star.video_details,

            loading: true,
          },
        },
      };
    case starConstants.GET_VIDEO_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          video_details: {
            ...state.star.video_details,
            data: {
              ...state.star.video_details.data,
              ...action.data,
            },
            loading: false,
          },
        },
      };
    case starConstants.GET_VIDEO_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          video_details: {
            ...state.star.video_details,
            loading: false,
          },
        },
      };
    case starConstants.STAR_CLEAR_EXISTING_VIDEO_ID:
      return {
        ...state,
        star: {
          ...state.star,
          videos: {
            ...state.star.videos,
            create: {
              loading: false,
            },
            existing_video: {
              data: {
                id: null,
                tagStarsIds: [],
                stars: [],
                type: '',
                url: '',
                source: '',
                videoThumbnail: '',
                title: '',
              },
            },
          },
        },
      };
    case starConstants.LINK_DATA_ENTRY_USERTO_STAR_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          link_data_entry_user: {
            starId: action.starId,
            loading: true,
          },
        },
      };
    case starConstants.LINK_DATA_ENTRY_USERTO_STAR_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          link_data_entry_user: {
            starId: action.starId,
            loading: false,
          },
          list: {
            ...state.star.list,
            content: state.star.list.content.map((star) =>
              star.starId === action.starId
                ? {...star, dataEntryUserId: action.dataEntryUserId}
                : star,
            ),
          },
        },
      };
    case starConstants.LINK_DATA_ENTRY_USERTO_STAR_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          link_data_entry_user: {
            starId: action.starId,
            loading: true,
          },
        },
      };

    case starConstants.SHOW_LINK_TEMPLATE_MODAL_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          show_template_link_modal: {
            show: action.boolean,
            drawerType: action.drawerType,
            id: action.id,
          },
        },
      };
    case starConstants.SHOW_LINK_TEMPLATE_MODAL_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          show_template_link_modal: {
            show: action.boolean,
            drawerType: action.drawerType,
            id: action.id,
          },
        },
      };
    case starConstants.LINK_VIDEO_TO_MOVIE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          link_video_to_movie: {
            templateId: action.templateId,
            loading: true,
          },
        },
      };
    case starConstants.LINK_VIDEO_TO_MOVIE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          link_video_to_movie: {
            templateId: action.templateId,
            loading: false,
          },
          show_template_link_modal: {
            show: false,
            drawerType: null,
            id: null,
          },
        },
      };
    case starConstants.LINK_VIDEO_TO_MOVIE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          link_video_to_movie: {
            templateId: action.templateId,
            loading: true,
          },
        },
      };
    case starConstants.SHOW_TEMPLATE_LINK_GALLERY_MODAL_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          show_gallery_template_link_modal: {
            show: action.boolean,
            drawerType: action.drawerType,
            id: action.id,
          },
        },
      };
    case starConstants.SHOW_TEMPLATE_LINK_GALLERY_MODAL_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          show_gallery_template_link_modal: {
            show: action.boolean,
            drawerType: action.drawerType,
            id: action.id,
          },
        },
      };
    case starConstants.LINK_MOVIE_TO_GALLERY_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          link_movie_to_gallery: {
            templateId: action.templateId,
            loading: true,
          },
        },
      };
    case starConstants.LINK_MOVIE_TO_GALLERY_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          link_movie_to_gallery: {
            templateId: action.templateId,
            loading: false,
          },
          show_gallery_template_link_modal: {
            show: false,
            drawerType: null,
            id: null,
          },
        },
      };
    case starConstants.LINK_MOVIE_TO_GALLERY_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          link_movie_to_gallery: {
            templateId: action.templateId,
            loading: true,
          },
        },
      };
    case starConstants.STAR_GET_AUDIO_LIST_REQUEST:
      return {
        ...state,

        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            list: {
              ...state.star.audio.list,
              loading: true,
            },
          },
        },
        isGalleryLoaded: true,
      };
    case starConstants.STAR_GET_AUDIO_LIST_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            list: {
              ...state.star.audio.list,
              data: {
                ...state.star.audio.list.data,
                ...action.audio,
                content: action.audio.content.map((audio) => ({
                  ...audio,
                  isChecked: false,
                })),
              },
              loading: false,
            },
          },
        },
        isGalleryLoaded: true,
      };
    case starConstants.STAR_GET_AUDIO_LIST_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            list: {
              ...state.star.audio.list,
              loading: false,
            },
          },
        },
      };

    case starConstants.STAR_DELETE_AUDIO_FILE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            audio_delete: {
              loading: true,
            },
          },
          audio_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_DELETE_AUDIO_FILE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            list: {
              ...state.star.audio.list,
              data: {
                ...state.star.audio.list.data,
                content: state.star.audio.list.data.content.filter(
                  (audio) => audio.id !== action.audio,
                ),
                number:
                  state.star.audio.list.data.numberOfElements - 1 === 0
                    ? Math.max(0, state.star.audio.list.data.number - 2)
                    : state.star.audio.list.data.number,
                numberOfElements:
                  state.star.audio.list.data.numberOfElements - 1,
                totalElements: state.star.audio.list.data.totalElements - 1,
                totalPages: Math.ceil(
                  state.star.audio.list.data.totalElements -
                    1 / state.star.audio.list.data.size,
                ),
              },
            },
            audio_delete: {
              loading: false,
            },
          },
          audio_edit: {
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.STAR_DELETE_AUDIO_FILE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            audio_delete: {
              loading: false,
            },
          },
          audio_edit: {
            loading: false,
            updated: false,
          },
        },
      };

    case starConstants.STAR_UPLOAD_AUDIO_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            audio_upload: {
              loading: true,
            },
          },
          audio_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_UPLOAD_AUDIO_IMAGE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            audio_upload: {
              loading: false,
            },
          },
          audio_edit: {
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.STAR_UPLOAD_AUDIO_IMAGE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            audio_upload: {
              loading: false,
            },
          },
          audio_edit: {
            loading: false,
            updated: false,
          },
        },
      };
    case starConstants.STAR_EDIT_AUDIO_TITLE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            audio_upload: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_EDIT_AUDIO_TITLE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            list: {
              ...state.star.audio.list,
              data: {
                ...state.star.audio.list.data,

                content: state.star.audio.list.data.content.map((audio) =>
                  audio.id === action.id ? action.audio : audio,
                ),
              },
            },
            audio_upload: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_EDIT_AUDIO_TITLE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            audio_upload: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_REQUEST:
      return {
        ...state,

        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            templates: {
              ...state.star.audio.templates,
              loading: true,
            },
          },
        },
        isGalleryLoaded: true,
      };
    case starConstants.STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            templates: {
              ...state.star.audio.templates,
              data: {
                ...state.star.audio.templates.data,

                content: action.audio ? [action.audio] : [],
              },
              loading: false,
            },
          },
        },
        isGalleryLoaded: true,
      };
    case starConstants.STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            templates: {
              ...state.star.audio.templates,
              loading: false,
            },
          },
        },
      };
    case starConstants.SHOW_ADD_COSMOS_VIDEO_MODAL:
      return {
        ...state,
        star: {
          ...state.star,
          show_add_cosmos_video_modal: {
            show: action.boolean,
            drawerType: action.drawerType,
            id: action.id,
          },
        },
      };
    case starConstants.HIDE_ADD_COSMOS_VIDEO_MODAL:
      return {
        ...state,
        star: {
          ...state.star,
          show_add_cosmos_video_modal: {
            show: action.boolean,
            drawerType: action.drawerType,
            id: action.id,
          },
        },
      };

    case starConstants.STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_REQUEST:
      return {
        ...state,

        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            languages: {
              ...state.star.audio.languages,
              data: [],
              loading: true,
            },
          },
        },
        isGalleryLoaded: true,
      };
    case starConstants.STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            languages: {
              ...state.star.audio.languages,
              data: action.audio,
              loading: false,
            },
          },
        },
        isGalleryLoaded: true,
      };
    case starConstants.STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          audio: {
            ...state.star.audio,
            languages: {
              ...state.star.audio.languages,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_UPLOAD_PREMIUM_VIDEO_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          premium_videos: {
            ...state.star.premium_videos,
            video_upload: {
              loading: true,
            },
          },
          video_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_UPLOAD_PREMIUM_VIDEO_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          premium_videos: {
            ...state.star.premium_videos,
            list: {
              data: {
                content: [
                  action.video,
                  ...state.star.premium_videos.list.data.content,
                ],
                first: true,
                last: true,
                number: state.star.premium_videos.list.data.number + 1,
                numberOfElements:
                  state.star.premium_videos.list.data.numberOfElements + 1,
                size: state.star.premium_videos.list.data.size,
                sort: state.star.premium_videos.list.data.sort,
                totalElements:
                  state.star.premium_videos.list.data.totalElements + 1,
                totalPages: state.star.premium_videos.list.data.totalPages,
                loading: false,
              },
              loading: false,
            },
            video_upload: {
              loading: false,
            },
          },
          video_edit: {
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.STAR_UPLOAD_PREMIUM_VIDEO_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          premium_videos: {
            ...state.star.premium_videos,
            video_upload: {
              loading: false,
            },
          },
          video_edit: {
            loading: false,
            updated: false,
          },
        },
      };

    case starConstants.STAR_EDIT_PREMIUM_VIDEO_CLIP_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          premium_videos: {
            ...state.star.premium_videos,
            video_upload: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_EDIT_PREMIUM_VIDEO_CLIP_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          premium_videos: {
            ...state.star.premium_videos,
            list: {
              ...state.star.premium_videos.list,
              data: {
                ...state.star.premium_videos.list.data,

                content: state.star.premium_videos.list.data.content.map(
                  (video) => (video.id === action.id ? action.video : video),
                ),
              },
            },
            video_upload: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_EDIT_PREMIUM_VIDEO_CLIP_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          premium_videos: {
            ...state.star.premium_videos,
            video_upload: {
              loading: false,
            },
          },
        },
      };

    case starConstants.STAR_GET_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            plans: {
              ...state.star.subscription.plans,
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_GET_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            plans: {
              ...state.star.subscription.plans,
              data: action.data,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_GET_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            plans: {
              ...state.star.subscription.plans,
              loading: false,
            },
          },
        },
      };

    case starConstants.STAR_ADD_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            create: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_ADD_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            create: {
              loading: false,
            },
            plans: {
              ...state.star.subscription.plans,
              data: action.data,
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_ADD_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            create: {
              loading: false,
            },
          },
        },
      };

    case starConstants.STAR_DELETE_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            plan_delete: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_DELETE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            plan_delete: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_DELETE_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          subscription: {
            ...state.star.subscription,
            plan_delete: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_ADD_NEWS_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            create: {
              loading: true,
            },
            status: 'started',
            alreadyexists_news: {
              data: {
                id: null,
              },
            },
          },
        },
      };
    case starConstants.STAR_ADD_NEWS_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            create: {
              loading: false,
            },
            status: 'created',
          },
        },
      };
    case starConstants.STAR_ADD_NEWS_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            create: {
              loading: false,
            },
            status: 'failed',
          },
        },
      };
    case starConstants.STAR_ADD_NEWS_EXISTS:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            create: {
              loading: false,
            },
            status: 'alreadyexists',
            alreadyexists_news: {
              data: action.data,
            },
          },
        },
      };
    case starConstants.STAR_UPDATE_NEWS_STATUS_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            update_status: {
              loading: true,
            },
          },
        },
      };
    case starConstants.STAR_UPDATE_NEWS_STATUS_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            list: {
              ...state.star.news.list,
              data: {
                ...state.star.news.list.data,
                content: state.star.news.list.data.content.filter(
                  (star) => star.id !== action.data.id,
                ),
              },
            },
            update_status: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_UPDATE_NEWS_STATUS_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          news: {
            ...state.star.news,
            update_status: {
              loading: false,
            },
          },
        },
      };
    case starConstants.STAR_UPDATE_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.STAR_UPDATE_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.STAR_UPDATE_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          gallery_edit: {
            ...state.star.gallery_edit,
            loading: false,
            updated: false,
          },
        },
      };

    case starConstants.CREATE_EVENT_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          events: {
            ...state.star.events,
            event_create: {
              loading: true,
              created: false,
            },
          },
          event_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          events: {
            ...state.star.events,
            event_create: {
              loading: false,
              created: true,
            },
          },
          event_edit: {
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.CREATE_EVENT_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          events: {
            ...state.star.events,
            event_create: {
              loading: false,
              created: false,
            },
          },
          event_edit: {
            loading: false,
            updated: false,
          },
        },
      };

    case starConstants.UPDATE_STAR_EVENT_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          events: {
            ...state.star.events,
            event_update: {
              loading: true,
              updated: false,
            },
          },
          event_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.UPDATE_STAR_EVENT_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          events: {
            ...state.star.events,
            event_update: {
              loading: false,
              updated: true,
            },
          },
          event_edit: {
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.UPDATE_STAR_EVENT_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          events: {
            ...state.star.events,
            event_udpate: {
              loading: false,
              updated: false,
            },
          },
          event_edit: {
            loading: false,
            updated: false,
          },
        },
      };

    case starConstants.DELETE_STAR_EVENT_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          event_edit: {
            loading: true,
            updated: false,
          },
        },
      };
    case starConstants.DELETE_STAR_EVENT_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          event_edit: {
            loading: false,
            updated: true,
          },
        },
      };
    case starConstants.DELETE_STAR_EVENT_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          event_edit: {
            loading: false,
            updated: false,
          },
        },
      };

    case starConstants.GET_EVENT_TYPES_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          event_types: {
            ...state.star.event_types,
            list: {
              ...state.star.event_types.list,
              loading: true,
            },
          },
        },
      };
    case starConstants.GET_EVENT_TYPES_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          event_types: {
            ...state.star.event_types,
            list: {
              ...state.star.event_types.list,
              data: action.event_types,
              loading: false,
            },
          },
        },
      };
    case starConstants.GET_EVENT_TYPES_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          event_types: {
            ...state.star.event_types,
            list: {
              ...state.star.event_types.list,
              loading: false,
            },
          },
        },
      };

    case starConstants.GET_PERK_TYPES_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          perk_types: {
            ...state.star.perk_types,
            list: {
              ...state.star.perk_types.list,
              loading: true,
            },
          },
        },
      };
    case starConstants.GET_PERK_TYPES_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          perk_types: {
            ...state.star.perk_types,
            list: {
              ...state.star.perk_types.list,
              data: action.perk_types,
              loading: false,
            },
          },
        },
      };
    case starConstants.GET_PERK_TYPES_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          perk_types: {
            ...state.star.perk_types,
            list: {
              ...state.star.perk_types.list,
              loading: false,
            },
          },
        },
      };
    case starConstants.GET_STAR_BANK_DETAILS_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          bank_details: {
            ...state.star.bank_details,
            loading: true,
          },
        },
      };
    case starConstants.GET_STAR_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          bank_details: {
            ...state.star.bank_details,
            loading: false,
            ...action.data,
          },
        },
      };
    case starConstants.GET_STAR_BANK_DETAILS_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          bank_details: {
            ...state.star.bank_details,
            loading: false,
          },
        },
      };
    case starConstants.GET_STAR_KYC_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          kyc_details: {
            ...state.star.kyc_details,
            loading: true,
          },
        },
      };
    case starConstants.GET_STAR_KYC_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          kyc_details: {
            ...state.star.kyc_details,
            loading: false,
            docs: [...action.data],
          },
        },
      };
    case starConstants.GET_STAR_KYC_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          kyc_details: {
            ...state.star.kyc_details,
            loading: false,
          },
        },
      };
    case starConstants.STAR_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          reset_password: {
            ...state.star.reset_password,
            loading: true,
            status: false,
          },
        },
      };
    case starConstants.STAR_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          reset_password: {
            ...state.star.reset_password,
            loading: false,
            status: true,
          },
        },
      };
    case starConstants.STAR_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          reset_password: {
            ...state.star.reset_password,
            loading: false,
            status: false,
          },
        },
      };

    case starConstants.GET_STAR_USER_INFO_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          linkedUserInfo: {
            ...state.star.linkedUserInfo,
            loading: true,
          },
        },
      };
    case starConstants.GET_STAR_USER_INFO_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          linkedUserInfo: {
            ...state.star.linkedUserInfo,
            ...action.data,
            loading: false,
          },
        },
      };
    case starConstants.GET_STAR_USER_INFO_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          linkedUserInfo: {
            ...state.star.linkedUserInfo,
            loading: false,
          },
        },
      };

    case starConstants.GET_STAR_USER_INFO_REQUEST:
      return {
        ...state,
        star: {
          ...state.star,
          linkedUserInfo: {
            ...state.star.linkedUserInfo,
            loading: true,
          },
        },
      };
    case starConstants.GET_STAR_USER_INFO_SUCCESS:
      return {
        ...state,
        star: {
          ...state.star,
          linkedUserInfo: {
            ...state.star.linkedUserInfo,
            ...action.data,
            loading: false,
          },
        },
      };
    case starConstants.GET_STAR_USER_INFO_FAILURE:
      return {
        ...state,
        star: {
          ...state.star,
          linkedUserInfo: {
            ...state.star.linkedUserInfo,
            loading: false,
          },
        },
      };

    default:
      return state;
  }
}
