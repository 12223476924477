export const authConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  STAR_ON_BOARD_REQUEST: 'STAR_ON_BOARD_REQUEST',
  STAR_ON_BOARD_SUCCESS: 'STAR_ON_BOARD_SUCCESS',
  STAR_ON_BOARD_FAILURE: 'STAR_ON_BOARD_FAILURE',
  STAR_ON_BOARD_VERIFY_MOBILE_REQUEST: 'STAR_ON_BOARD_VERIFY_MOBILE_REQUEST',
  STAR_ON_BOARD_VERIFY_MOBILE_SUCCESS: 'STAR_ON_BOARD_VERIFY_MOBILE_SUCCESS',
  STAR_ON_BOARD_VERIFY_MOBILE_FAILURE: 'STAR_ON_BOARD_VERIFY_MOBILE_FAILURE',
  STAR_ON_BOARD_VERIFY_EMAIL_REQUEST: 'STAR_ON_BOARD_VERIFY_EMAIL_REQUEST',
  STAR_ON_BOARD_VERIFY_EMAIL_SUCCESS: 'STAR_ON_BOARD_VERIFY_EMAIL_SUCCESS',
  STAR_ON_BOARD_VERIFY_EMAIL_FAILURE: 'STAR_ON_BOARD_VERIFY_EMAIL_FAILURE',
  STAR_OTP_STATE_REQUEST: 'STAR_OTP_STATE_REQUEST',
  STAR_OTP_STATE_SUCCESS: 'STAR_OTP_STATE_SUCCESS',
  STAR_OTP_STATE_FAILURE: 'STAR_OTP_STATE_FAILURE',
  LOGOUT: 'USERS_LOGOUT',

  GET_OTP_RESET_PASSWORD_REQUEST: 'GET_OTP_RESET_PASSWORD_REQUEST',
  GET_OTP_RESET_PASSWORD_SUCCESS: 'GET_OTP_RESET_PASSWORD_SUCCESS',
  GET_OTP_RESET_PASSWORD_FAILURE: 'GET_OTP_RESET_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  STAR_ON_BOARD_SEND_OTP_REQUEST: 'STAR_ON_BOARD_SEND_OTP_REQUEST',
  STAR_ON_BOARD_SEND_OTP_SUCCESS: 'STAR_ON_BOARD_SEND_OTP_SUCCESS',
  STAR_ON_BOARD_SEND_OTP_FAILURE: 'STAR_ON_BOARD_SEND_OTP_FAILURE',

  STAR_ON_BOARD_RESET_OTP_SENT_STATUS: 'STAR_ON_BOARD_RESET_OTP_SENT_STATUS',
};
