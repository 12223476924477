export const postConstants = {
  POST_GETALL_REQUEST: "POST_GETALL_REQUEST",
  POST_GETALL_SUCCESS: "POST_GETALL_SUCCESS",
  POST_GETALL_FAILURE: "POST_GETALL_FAILURE",
  POST_ADD_REQUEST: "POST_ADD_REQUEST",
  POST_ADD_SUCCESS: "POST_ADD_SUCCESS",
  POST_ADD_FAILURE: "POST_ADD_FAILURE",
  POST_UPDATE_REQUEST: "POST_UPDATE_REQUEST",
  POST_UPDATE_SUCCESS: "POST_UPDATE_SUCCESS",
  POST_UPDATE_FAILURE: "POST_UPDATE_FAILURE",
  POST_UPLOAD_BANNER_IMAGE_REQUEST: "POST_UPLOAD_BANNER_IMAGE_REQUEST",
  POST_UPLOAD_BANNER_IMAGE_SUCCESS: "POST_UPLOAD_BANNER_IMAGE_SUCCESS",
  POST_UPLOAD_BANNER_IMAGE_FAILURE: "POST_UPLOAD_BANNER_IMAGE_FAILURE",
  POST_MARKFORDELETE_REQUEST: "POST_MARKFORDELETE_REQUEST",
  POST_MARKFORDELETE_SUCCESS: "POST_MARKFORDELETE_SUCCESS",
  POST_MARKFORDELETE_FAILURE: "POST_MARKFORDELETE_FAILURE",
  POST_VIEW_RESULT_REQUEST: "POST_VIEW_RESULT_REQUEST",
  POST_VIEW_RESULT_SUCCESS: "POST_VIEW_RESULT_SUCCESS",
  POST_VIEW_RESULT_FAILURE: "POST_VIEW_RESULT_FAILURE",
  POST_GET_REQUEST: "POST_GET_REQUEST",
  POST_GET_SUCCESS: "POST_GET_SUCCESS",
  POST_GET_FAILURE: "POST_GET_FAILURE",
  POST_GET_GALLERY_REQUEST: "POST_GET_GALLERY_REQUEST",
  POST_GET_GALLERY_SUCCESS: "POST_GET_GALLERY_SUCCESS",
  POST_GET_GALLERY_FAILURE: "POST_GET_GALLERY_FAILURE",
  POST_UPLOAD_GALLERY_REQUEST: "POST_UPLOAD_GALLERY_REQUEST",
  POST_UPLOAD_GALLERY_SUCCESS: "POST_UPLOAD_GALLERY_SUCCESS",
  POST_UPLOAD_GALLERY_FAILURE: "POST_UPLOAD_GALLERY_FAILURE",
  POST_GALLERY_MODAL_OPEN: "POST_GALLERY_MODAL_OPEN",
  POST_GALLERY_MODAL_CLOSE: "POST_GALLERY_MODAL_CLOSE",
  POST_STAR_DELETE_REQUEST: "POST_STAR_DELETE_REQUEST",
  POST_STAR_DELETE_SUCCESS: "POST_STAR_DELETE_SUCCESS",
  POST_STAR_DELETE_FAILURE: "POST_STAR_DELETE_FAILURE",
  POST_UPDATE_LINKED_STARS: "POST_UPDATE_LINKED_STARS",
  POST_REMOVE_LINKED_STARS: "POST_REMOVE_LINKED_STARS",
};
