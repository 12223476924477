import {authConstants} from '../constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? {
      loggedIn: true,
      user,
      error: null,
      star_onboard: {
        loading: null,
      },
      star_onboard_verify_mobile: {
        loading: null,
      },
      star_onboard_verify_email: {
        loading: null,
      },
    }
  : {
      loggedIn: false,
      error: null,
      star_onboard: {
        loading: null,
        otpSent: false,
        mobile_verified: false,
        email_verified: false,
        resetPassword: false,
      },
      star_onboard_verify_mobile: {
        loading: null,
      },
      star_onboard_verify_email: {
        loading: null,
      },
      reset_password: {
        loading: false,
        email_submitted: false,
      },
    };

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
        loading: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        user: action.user,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loading: false,
        error: action.error,
      };
    case authConstants.STAR_ON_BOARD_SEND_OTP_REQUEST:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: true,
        },
      };
    case authConstants.STAR_ON_BOARD_SEND_OTP_SUCCESS:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
          otpSent: true,
        },
      };
    case authConstants.STAR_ON_BOARD_SEND_OTP_FAILURE:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
        },
      };
    case authConstants.STAR_ON_BOARD_RESET_OTP_SENT_STATUS:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          otpSent: false,
          resetPassword: !state.star_onboard.resetPassword,
        },
      };
    case authConstants.STAR_ON_BOARD_REQUEST:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: true,
        },
      };
    case authConstants.STAR_ON_BOARD_SUCCESS:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
        },
      };
    case authConstants.STAR_ON_BOARD_FAILURE:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
        },
      };
    case authConstants.STAR_ON_BOARD_VERIFY_MOBILE_REQUEST:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: true,
        },
        star_onboard_verify_mobile: {
          loading: true,
        },
      };
    case authConstants.STAR_ON_BOARD_VERIFY_MOBILE_SUCCESS:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
          mobile_verified: true,
        },
        star_onboard_verify_mobile: {
          loading: false,
        },
      };
    case authConstants.STAR_ON_BOARD_VERIFY_MOBILE_FAILURE:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
        },
        star_onboard_verify_mobile: {
          loading: false,
        },
      };
    case authConstants.STAR_ON_BOARD_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: true,
        },
        star_onboard_verify_email: {
          loading: true,
        },
      };
    case authConstants.STAR_ON_BOARD_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
          email_verified: true,
        },
        star_onboard_verify_email: {
          loading: false,
        },
      };
    case authConstants.STAR_ON_BOARD_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        star_onboard: {
          ...state.star_onboard,
          loading: false,
        },
        star_onboard_verify_email: {
          loading: false,
        },
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        loggingIn: false,
        loading: false,
        user: {},
      };

    case authConstants.STAR_OTP_STATE_REQUEST:
      return {
        ...state,
        star_otp_state: {
          loading: true,
        },
      };
    case authConstants.STAR_OTP_STATE_SUCCESS:
      return {
        ...state,
        star_otp_state: {
          loading: false,
        },
      };
    case authConstants.STAR_OTP_STATE_FAILURE:
      return {
        ...state,
        star_otp_state: {
          loading: false,
        },
      };
    case authConstants.GET_OTP_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        reset_password: {
          ...state.reset_password,
          loading: true,
        },
      };
    case authConstants.GET_OTP_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset_password: {
          ...state.reset_password,
          email_submitted: true,
          loading: false,
        },
      };
    case authConstants.GET_OTP_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        reset_password: {
          ...state.reset_password,
          loading: false,
        },
      };
    case authConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        reset_password: {
          ...state.reset_password,
          loading: true,
        },
      };
    case authConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset_password: {
          ...state.reset_password,
          loading: false,
        },
      };
    case authConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        reset_password: {
          ...state.reset_password,
          loading: false,
        },
      };
    default:
      return state;
  }
}
