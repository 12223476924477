let config = {};

if (process.env.REACT_APP_BASE_URL) {
  config = {
    apiEndpoint: process.env.REACT_APP_BASE_URL,
    apiUrl: process.env.REACT_APP_VERSION,
    starApiUrl: process.env.REACT_APP_STAR_VERSION,
    mobileUrl: process.env.REACT_APP_MOBILE_URL,
  };
}

export const appConfig = {
  ...config,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyAalsfHPi1F4UyuLZ8C3P0iV34-OaVdF3U',
  authDomain: 'starbeat-msg.firebaseapp.com',
  databaseURL: 'https://starbeat-msg.firebaseio.com',
  projectId: 'starbeat-msg',
  storageBucket: 'starbeat-msg.appspot.com',
  messagingSenderId: '70726994617',
  appId: '1:70726994617:web:10df1ffbea1232b21de968',
};

export const youtubeApiKeys = [
  'AIzaSyBi8szzrhC60UqfLkplRWAONr4KVJ_kX8U',
  'AIzaSyBaZsaQuk0c-o4my6svhlsCM1CMUjSHqds',
  'AIzaSyAIHz1EVa-C1q9ODsIKQY1o2MLYGS0ErVk'
]
