import {combineReducers} from 'redux';
import {auth} from './auth.reducer';
import {alert} from './alert.reducer';
import {star} from './star.reducer';
import {template} from './template.reducer';
import {errorhandler} from './errorhandler.reducer';
import {post} from './post.reducer';
import {chat} from './chat.reducer';

const rootReducer = combineReducers({
  auth,
  alert,
  star,
  template,
  errorhandler,
  post,
  chat
});

export default rootReducer;
