import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import Modal from 'react-modal';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './helpers';

const queryClient = new QueryClient();
const rootElement = document.getElementById('root');
rootElement.className = "flex min-h-full";

Modal.setAppElement("#modal");

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
