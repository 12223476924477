import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { initializeApp } from 'firebase/app';

import Loader from './components/common/loader';
import PageNotFound from './components/common/pageNotFound';
import ThemeModeContext from './contexts/themeMode';
import { firebaseConfig, history, Role } from './helpers';

import 'react-toastify/dist/ReactToastify.css';
import { isDarkMode } from './components/utilities/commonMethods';

const DEFAULT_LOADER = <Loader className="my-[50vh] mx-auto" />;
const loading = () => DEFAULT_LOADER;

const Login = Loadable({
  loader: () => import('./components/auth/Login'),
  loading,
});

const SignUp = Loadable({
  loader: () => import('./components/auth/Signup'),
  loading,
});

const VerifyEmail = Loadable({
  loader: () => import('./components/auth/VerifyEmail'),
  loading,
});

const VerifyMobile = Loadable({
  loader: () => import('./components/auth/VerifyMobile'),
  loading,
});

const ResetPassword = Loadable({
  loader: () => import('./components/auth/ResetPassword'),
  loading,
});

const Dashboard = Loadable({
  loader: () => import('./components/dashboard/Dashboard'),
  loading,
});

const Gallery = Loadable({
  loader: () => import('./components/gallery/Gallery'),
  loading,
});

const NewGallery = Loadable({
  loader: () => import('./components/gallery/NewGallery'),
  loading,
});

const Videos = Loadable({
  loader: () => import('./components/videos/Videos'),
  loading,
});

const NewVideo = Loadable({
  loader: () => import('./components/videos/NewVideo'),
  loading,
});

const NewYoutubeVideo = Loadable({
  loader: () => import('./components/videos/NewYoutubeVideo'),
  loading,
});

const Audios = Loadable({
  loader: () => import('./components/audios/Audios'),
  loading,
});

const NewAudio = Loadable({
  loader: () => import('./components/audios/newAudio'),
  loading,
});

const Polls = Loadable({
  loader: () => import('./components/polls/Polls'),
  loading,
});

const NewPoll = Loadable({
  loader: () => import('./components/polls/NewPoll'),
  loading,
});

const Events = Loadable({
  loader: () => import('./components/events/Events'),
  loading,
});

const NewEvent = Loadable({
  loader: () => import('./components/events/NewEvent'),
  loading,
});

const Playlists = Loadable({
  loader: () => import('./components/playlists/Playlists'),
  loading,
});

const EditPlaylist = Loadable({
  loader: () => import('./components/playlists/EditPlaylist'),
  loading,
});

const NewAudioPlaylist = Loadable({
  loader: () => import('./components/playlists/NewAudioPlaylist'),
  loading,
});

const NewVideoPlaylist = Loadable({
  loader: () => import('./components/playlists/NewVideoPlaylist'),
  loading,
});

const Campaigns = Loadable({
  loader: () => import('./components/campaigns/Campaigns'),
  loading,
});

const NewCampaign = Loadable({
  loader: () => import('./components/campaigns/NewCampaign'),
  loading,
});

const Merchandise = Loadable({
  loader: () => import('./components/merchandise/Merchandise'),
  loading,
});

const NewMerchandiseItem = Loadable({
  loader: () => import('./components/merchandise/NewMerchandiseItem'),
  loading
});

const MyProfile = Loadable({
  loader: () => import('./components/myprofile/Profile'),
  loading,
});

const Plans = Loadable({
  loader: () => import('./components/myprofile/Plans'),
  loading,
});

const PrivateRoute = Loadable({
  loader: () => import('./helpers/privateRoute'),
  loading,
});

const Fans = Loadable({
  loader: () => import('./components/myprofile/Fans'),
  loading,
});

const Followers = Loadable({
  loader: () => import('./components/myprofile/Followers'),
  loading,
});

const Earnings = Loadable({
  loader: () => import('./components/myprofile/Earnings'),
  loading,
});

const Chat = Loadable({
  loader: () => import('./components/chat'),
  loading,
});

const UserProfile = Loadable({
  loader: () => import('./components/userProfile'),
  loading,
});

const NotificationList = Loadable({
  loader: () => import('./components/notifications'),
  loading,
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false,
      themeMode: isDarkMode() ? 'dark' : 'light',
    };
  }

  async componentDidMount() {
    window.env = process.env;
    let user = JSON.parse(localStorage.getItem('user'));
    this.setState({
      currentUser: user,
      isAdmin: user && user.role === Role.Admin,
    });
    initializeApp(firebaseConfig);
  }

  setMode = (newThemeMode) => {
    this.setState((prevState) => ({
      ...prevState,
      themeMode: newThemeMode,
    }));
  }

  render() {
    return (
      <ThemeModeContext.Provider
        value={{
          mode: this.state.themeMode,
          setMode: this.setMode
        }}
      >
        <Router history={history}>
          <>
            <Helmet bodyAttributes={{ class: "h-full overflow-x-hidden font-sans antialiased text-gray-900 bg-gray-50 dark:bg-gray-800 dark:text-gray-50" }}>
              <title>Starbeat</title>
            </Helmet>
            <Switch>
              <PrivateRoute exact path='/' component={Dashboard} />
              <PrivateRoute exact path='/gallery' component={Gallery} />
              <PrivateRoute exact path='/gallery/new' component={NewGallery} />
              <PrivateRoute exact path='/gallery/:id/edit' component={NewGallery} />
              <PrivateRoute exact path='/videos' component={Videos} />
              <PrivateRoute exact path='/videos/new' component={NewVideo} />
              <PrivateRoute exact path='/videos/:id/edit' component={NewVideo} />
              <PrivateRoute exact path='/videos/new-youtube-video' component={NewYoutubeVideo} />
              <PrivateRoute exact path='/videos/:id/edit-youtube-video' component={NewYoutubeVideo} />
              <PrivateRoute exact path='/audios' component={Audios} />
              <PrivateRoute exact path='/audios/new' component={NewAudio} />
              <PrivateRoute exact path='/audios/:id/edit' component={NewAudio} />
              <PrivateRoute exact path='/polls' component={Polls} />
              <PrivateRoute exact path='/polls/new' component={NewPoll} />
              <PrivateRoute exact path='/polls/:id/edit' component={NewPoll} />
              <PrivateRoute exact path='/events' component={Events} />
              <PrivateRoute exact path='/events/new' component={NewEvent} />
              <PrivateRoute exact path='/events/:id/edit' component={NewEvent} />
              <PrivateRoute exact path='/playlists' component={Playlists} />
              <PrivateRoute exact path='/playlists/:id/edit' component={EditPlaylist} />
              <PrivateRoute exact path='/playlists/audios/new' component={NewAudioPlaylist} />
              <PrivateRoute exact path='/playlists/videos/new' component={NewVideoPlaylist} />
              <PrivateRoute exact path='/campaigns' component={Campaigns} />
              <PrivateRoute exact path='/campaigns/new' component={NewCampaign} />
              <PrivateRoute exact path='/campaigns/:id/edit' component={NewCampaign} />
              <PrivateRoute exact path='/merchandise' component={Merchandise} />
              <PrivateRoute exact path='/merchandise/new' component={NewMerchandiseItem} />
              <PrivateRoute exact path='/merchandise/:id/edit' component={NewMerchandiseItem} />
              <PrivateRoute exact path='/fans/:id' component={UserProfile} />
              <PrivateRoute exact path='/followers/:id' component={UserProfile} />
              <PrivateRoute path='/profile' component={MyProfile} />
              <PrivateRoute exact path='/plans' component={Plans} />
              <PrivateRoute exact path='/fans' component={Fans} />
              <PrivateRoute exact path='/followers' component={Followers} />
              <PrivateRoute exact path='/earnings' component={Earnings} />
              <PrivateRoute exact path='/chat' component={Chat} />
              <PrivateRoute exact path='/notifications' component={NotificationList} />
              <Route exact path='/web/login' component={Login} />
              <Route exact path='/web/onboard/star/:key' component={SignUp} />
              <Route
                exact
                path='/web/verify/email/:key'
                component={VerifyEmail}
              />
              <Route
                exact
                path='/web/verify/mobile/:key'
                component={VerifyMobile}
              />
              <Route
                exact
                path='/web/resetpassword'
                component={ResetPassword}
              />
              <Route component={PageNotFound} />
            </Switch>
            <ToastContainer autoClose={2000} />
          </>
        </Router>
      </ThemeModeContext.Provider>
    );
  }
}

export default App;
