export const starConstants = {
  STARS_GETALL_REQUEST: 'STARS_GETALL_REQUEST',
  STARS_GETALL_SUCCESS: 'STARS_GETALL_SUCCESS',
  STARS_GETALL_FAILURE: 'STARS_GETALL_FAILURE',
  STAR_GET_REQUEST: 'STAR_GET_REQUEST',
  STAR_GET_SUCCESS: 'STAR_GET_SUCCESS',
  STAR_GET_FAILURE: 'STAR_GET_FAILURE',
  STAR_DELETE_REQUEST: 'STAR_DELETE_REQUEST',
  STAR_DELETE_SUCCESS: 'STAR_DELETE_SUCCESS',
  STAR_DELETE_FAILURE: 'STAR_DELETE_FAILURE',
  STAR_CATEGORIES_REQUEST: 'STAR_CATEGORIES_REQUEST',
  STAR_CATEGORIES_SUCCESS: 'STAR_CATEGORIES_SUCCESS',
  STAR_CATEGORIES_FAILURE: 'STAR_CATEGORIES_FAILURE',
  STAR_ADD_REQUEST: 'STAR_ADD_REQUEST',
  STAR_ADD_SUCCESS: 'STAR_ADD_SUCCESS',
  STAR_ADD_FAILURE: 'STAR_ADD_FAILURE',
  STAR_UPDATEPROFILE_REQUEST: 'STAR_UPDATEPROFILE_REQUEST',
  STAR_UPDATEPROFILE_SUCCESS: 'STAR_UPDATEPROFILE_SUCCESS',
  STAR_UPDATEPROFILE_FAILURE: 'STAR_UPDATEPROFILE_FAILURE',
  STAR_GETMOVIES_REQUEST: 'STAR_GETMOVIES_REQUEST',
  STAR_GETMOVIES_SUCCESS: 'STAR_GETMOVIES_SUCCESS',
  STAR_GETMOVIES_FAILURE: 'STAR_GETMOVIES_FAILURE',
  STAR_UPLOAD_PROFILE_IMAGE_REQUEST: 'STAR_UPLOAD_PROFILE_IMAGE_REQUEST',
  STAR_UPLOAD_PROFILE_IMAGE_SUCCESS: 'STAR_UPLOAD_PROFILE_IMAGE_SUCCESS',
  STAR_UPLOAD_PROFILE_IMAGE_FAILURE: 'STAR_UPLOAD_PROFILE_IMAGE_FAILURE',
  STAR_UPLOAD_BANNER_IMAGE_REQUEST: 'STAR_UPLOAD_BANNER_IMAGE_REQUEST',
  STAR_UPLOAD_BANNER_IMAGE_SUCCESS: 'STAR_UPLOAD_BANNER_IMAGE_SUCCESS',
  STAR_UPLOAD_BANNER_IMAGE_FAILURE: 'STAR_UPLOAD_BANNER_IMAGE_FAILURE',
  STAR_DELETE_PROFILE_IMAGE_REQUEST: 'STAR_DELETE_PROFILE_IMAGE_REQUEST',
  STAR_DELETE_PROFILE_IMAGE_SUCCESS: 'STAR_DELETE_PROFILE_IMAGE_SUCCESS',
  STAR_DELETE_PROFILE_IMAGE_FAILURE: 'STAR_DELETE_PROFILE_IMAGE_FAILURE',
  STAR_DELETE_BANNER_IMAGE_REQUEST: 'STAR_DELETE_BANNER_IMAGE_REQUEST',
  STAR_DELETE_BANNER_IMAGE_SUCCESS: 'STAR_DELETE_BANNER_IMAGE_SUCCESS',
  STAR_DELETE_BANNER_IMAGE_FAILURE: 'STAR_DELETE_BANNER_IMAGE_FAILURE',
  STAR_DELETE_GALLERY_IMAGE_REQUEST: 'STAR_DELETE_GALLERY_IMAGE_REQUEST',
  STAR_DELETE_GALLERY_IMAGE_SUCCESS: 'STAR_DELETE_GALLERY_IMAGE_SUCCESS',
  STAR_DELETE_GALLERY_IMAGE_FAILURE: 'STAR_DELETE_GALLERY_IMAGE_FAILURE',
  STAR_TAG_STARS_TO_GALLERY__REQUEST: 'STAR_DELETE_GALLERY_IMAGE_REQUEST',
  STAR_TAG_STARS_TO_GALLERY_SUCCESS: 'STAR_TAG_STARS_TO_GALLERY_SUCCESS',
  STAR_TAG_STARS_TO_GALLERY_FAILURE: 'STAR_TAG_STARS_TO_GALLERY_FAILURE',
  STAR_GET_TAGGED_STARS_REQUEST: 'STAR_GET_TAGGED_STARS_REQUEST',
  STAR_GET_TAGGED_STARS_SUCCESS: 'STAR_GET_TAGGED_STARS_SUCCESS',
  STAR_GET_TAGGED_STARS_FAILURE: 'STAR_GET_TAGGED_STARS_FAILURE',
  STAR_DELETE_TAGGED_STARS_REQUEST: 'STAR_DELETE_TAGGED_STARS_REQUEST',
  STAR_DELETE_TAGGED_STARS_SUCCESS: 'STAR_DELETE_TAGGED_STARS_SUCCESS',
  STAR_DELETE_TAGGED_STARS_FAILURE: 'STAR_DELETE_TAGGED_STARS_FAILURE',
  STAR_DELETE_VIDEO_CHANNEL_REQUEST: 'STAR_DELETE_VIDEO_CHANNEL_REQUEST',
  STAR_DELETE_VIDEO_CHANNEL_SUCCESS: 'STAR_DELETE_VIDEO_CHANNEL_SUCCESS',
  STAR_DELETE_VIDEO_CHANNEL_FAILURE: 'STAR_DELETE_VIDEO_CHANNEL_FAILURE',
  STAR_ADD_VIDEO_CHANNEL_REQUEST: 'STAR_ADD_VIDEO_CHANNEL_REQUEST',
  STAR_ADD_VIDEO_CHANNEL_SUCCESS: 'STAR_ADD_VIDEO_CHANNEL_SUCCESS',
  STAR_ADD_VIDEO_CHANNEL_FAILURE: 'STAR_ADD_VIDEO_CHANNEL_FAILURE',
  STAR_UPLOAD_GALLERY_IMAGE_REQUEST: 'STAR_UPLOAD_GALLERY_IMAGE_REQUEST',
  STAR_UPLOAD_GALLERY_IMAGE_SUCCESS: 'STAR_UPLOAD_GALLERY_IMAGE_SUCCESS',
  STAR_UPLOAD_GALLERY_IMAGE_FAILURE: 'STAR_UPLOAD_GALLERY_IMAGE_FAILURE',
  STAR_VERIFY_REQUEST: 'STAR_VERIFY_REQUEST',
  STAR_VERIFY_SUCCESS: 'STAR_VERIFY_SUCCESS',
  STAR_VERIFY_FAILURE: 'STAR_VERIFY_FAILURE',
  STAR_MARKFORDELETE_REQUEST: 'STAR_MARKFORDELETE_REQUEST',
  STAR_MARKFORDELETE_SUCCESS: 'STAR_MARKFORDELETE_SUCCESS',
  STAR_MARKFORDELETE_FAILURE: 'STAR_MARKFORDELETE_FAILURE',
  STAR_MARKFORUNDELETE_REQUEST: 'STAR_MARKFORUNDELETE_REQUEST',
  STAR_MARKFORUNDELETE_SUCCESS: 'STAR_MARKFORUNDELETE_SUCCESS',
  STAR_MARKFORUNDELETE_FAILURE: 'STAR_MARKFORUNDELETE_FAILURE',
  STAR_DOWNLOAD_IMAGE_FROM_URL_REQUEST: 'STAR_DOWNLOAD_IMAGE_FROM_URL_REQUEST',
  STAR_DOWNLOAD_IMAGE_FROM_URL_SUCCESS: 'STAR_DOWNLOAD_IMAGE_FROM_URL_SUCCESS',
  STAR_DOWNLOAD_IMAGE_FROM_URL_FAILURE: 'STAR_DOWNLOAD_IMAGE_FROM_URL_FAILURE',
  STAR_GALLERY_MODAL_OPEN: 'STAR_GALLERY_MODAL_OPEN',
  STAR_GALLERY_MODAL_CLOSE: 'STAR_GALLERY_MODAL_CLOSE',
  STAR_TAG_MODAL_CLOSE: 'STAR_TAG_MODAL_CLOSE',
  STAR_GROUP_REQUEST: 'STAR_GROUP_REQUEST',
  STAR_GROUP_SUCCESS: 'STAR_GROUP_SUCCESS',
  STAR_GROUP_FAILURE: 'STAR_GROUP_FAILURE',
  STAR_GROUPED_DELETE_REQUEST: 'STAR_GROUPED_DELETE_REQUEST',
  STAR_GROUPED_DELETE_SUCCESS: 'STAR_GROUPED_DELETE_SUCCESS',
  STAR_GROUPED_DELETE_FAILURE: 'STAR_GROUPED_DELETE_FAILURE',
  STARS_GETALL_BYSEARCH_REQUEST: 'STARS_GETALL_BYSEARCH_REQUEST',
  STARS_GETALL_BYSEARCH_SUCCESS: 'STARS_GETALL_BYSEARCH_SUCCESS',
  STARS_GETALL_BYSEARCH_FAILURE: 'STARS_GETALL_BYSEARCH_FAILURE',
  STAR_SAVE_AND_GO: 'STAR_SAVE_AND_GO',
  STAR_GETEXTENAL_ID_REQUEST: 'STAR_GETEXTENAL_ID_REQUEST',
  STAR_GETEXTENAL_ID_SUCCESS: 'STAR_GETEXTENAL_ID_SUCCESS',
  STAR_GETEXTENAL_ID_FAILURE: 'STAR_GETEXTENAL_ID_FAILURE',
  STAR_GET_NEWS_REQUEST: 'STAR_GET_NEWS_REQUEST',
  STAR_GET_NEWS_SUCCESS: 'STAR_GET_NEWS_SUCCESS',
  STAR_GET_NEWS_FAILURE: 'STAR_GET_NEWS_FAILURE',
  STAR_ADD_VIDEO_REQUEST: 'STAR_ADD_VIDEO_REQUEST',
  STAR_ADD_VIDEO_SUCCESS: 'STAR_ADD_VIDEO_SUCCESS',
  STAR_ADD_VIDEO_FAILURE: 'STAR_ADD_VIDEO_FAILURE',
  STAR_DELETE_VIDEO_REQUEST: 'STAR_DELETE_VIDEO_REQUEST',
  STAR_DELETE_VIDEO_SUCCESS: 'STAR_DELETE_VIDEO_SUCCESS',
  STAR_DELETE_VIDEO_FAILURE: 'STAR_DELETE_VIDEO_FAILURE',
  TAG_STARS_TO_VIDEO_SUCCESS: 'TAG_STARS_TO_VIDEO_SUCCESS',
  TAG_STARS_TO_VIDEO_FAILURE: 'TAG_STARS_TO_VIDEO_FAILURE',
  VIDEO_GET_TAGGED_STARS_SUCCESS: 'VIDEO_GET_TAGGED_STARS_SUCCESS',
  VIDEO_GET_TAGGED_STARS_FAILURE: 'VIDEO_GET_TAGGED_STARS_FAILURE',
  REMOVE_STARS_FROM_VIDEOS_SUCCESS: 'REMOVE_STARS_FROM_VIDEOS_SUCCESS',
  REMOVE_STARS_FROM_VIDEOS_FAILURE: 'REMOVE_STARS_FROM_VIDEOS_FAILURE',
  STAR_SEARCH_BY_ID_REQUEST: 'STAR_SEARCH_BY_ID_REQUEST',
  STAR_SEARCH_BY_ID_SUCCESS: 'STAR_SEARCH_BY_ID_SUCCESS',
  STAR_SEARCH_BY_ID_FAILURE: 'STAR_SEARCH_BY_ID_FAILURE',
  STAR_ADD_EXTERNALID_REQUEST: 'STAR_ADD_EXTERNALID_REQUEST',
  STAR_ADD_EXTERNALID_SUCCESS: 'STAR_ADD_EXTERNALID_SUCCESS',
  STAR_ADD_EXTERNALID_FAILURE: 'STAR_ADD_EXTERNALID_FAILURE',
  STAR_UPDATE_ALIAS_FAILURE: 'STAR_UPDATE_ALIAS_FAILURE',
  STAR_GET_TIKTOK_MEDIA_REQUEST: 'STAR_GET_TIKTOK_MEDIA_REQUEST',
  STAR_GET_TIKTOK_MEDIA_SUCCESS: 'STAR_GET_TIKTOK_MEDIA_SUCCESS',
  STAR_GET_TIKTOK_MEDIA_FAILURE: 'STAR_GET_TIKTOK_MEDIA_FAILURE',
  STAR_GET_INSTAGRAM_MEDIA_REQUEST: 'STAR_GET_INSTAGRAM_MEDIA_REQUEST',
  STAR_GET_INSTAGRAM_MEDIA_SUCCESS: 'STAR_GET_INSTAGRAM_MEDIA_SUCCESS',
  STAR_GET_INSTAGRAM_MEDIA_FAILURE: 'STAR_GET_INSTAGRAM_MEDIA_FAILURE',
  STAR_GET_FACEBOOK_MEDIA_REQUEST: 'STAR_GET_FACEBOOK_MEDIA_REQUEST',
  STAR_GET_FACEBOOK_MEDIA_SUCCESS: 'STAR_GET_FACEBOOK_MEDIA_SUCCESS',
  STAR_GET_FACEBOOK_MEDIA_FAILURE: 'STAR_GET_FACEBOOK_MEDIA_FAILURE',
  STAR_GET_S3_MEDIA_URL_REQUEST: 'STAR_GET_S3_MEDIA_URL_REQUEST',
  STAR_GET_S3_MEDIA_URL_SUCCESS: 'STAR_GET_S3_MEDIA_URL_SUCCESS',
  STAR_GET_S3_MEDIA_URL_FAILURE: 'STAR_GET_S3_MEDIA_URL_FAILURE',
  MISTAG_UNLINK_STAR_REQUEST: 'MISTAG_UNLINK_STAR_REQUEST',
  MISTAG_UNLINK_STAR_SUCCESS: 'MISTAG_UNLINK_STAR_SUCCESS',
  MISTAG_UNLINK_STAR_FAILURE: 'MISTAG_UNLINK_STAR_FAILURE',
  STARS_CLEAR: 'STARS_CLEAR',
  GET_VIDEO_REQUEST: 'GET_VIDEO_REQUEST',
  GET_VIDEO_SUCCESS: 'GET_VIDEO_SUCCESS',
  GET_VIDEO_FAILURE: 'GET_VIDEO_FAILURE',
  STAR_CLEAR_EXISTING_VIDEO_ID: 'STAR_CLEAR_EXISTING_VIDEO_ID',

  LINK_DATA_ENTRY_USERTO_STAR_REQUEST: 'LINK_DATA_ENTRY_USERTO_STAR_REQUEST',
  LINK_DATA_ENTRY_USERTO_STAR_SUCCESS: 'LINK_DATA_ENTRY_USERTO_STAR_SUCCESS',
  LINK_DATA_ENTRY_USERTO_STAR_FAILURE: 'LINK_DATA_ENTRY_USERTO_STAR_REQUEST',

  SHOW_LINK_TEMPLATE_MODAL_REQUEST: 'SHOW_LINK_TEMPLATE_MODAL_REQUEST',
  SHOW_LINK_TEMPLATE_MODAL_SUCCESS: 'SHOW_LINK_TEMPLATE_MODAL_SUCCESS',

  LINK_VIDEO_TO_MOVIE_REQUEST: 'LINK_VIDEO_TO_MOVIE_REQUEST',
  LINK_VIDEO_TO_MOVIE_SUCCESS: 'LINK_VIDEO_TO_MOVIE_SUCCESS',
  LINK_VIDEO_TO_MOVIE_FAILURE: 'LINK_VIDEO_TO_MOVIE_FAILURE',

  SHOW_TEMPLATE_LINK_GALLERY_MODAL_REQUEST:
    'SHOW_TEMPLATE_LINK_GALLERY_MODAL_REQUEST',
  SHOW_TEMPLATE_LINK_GALLERY_MODAL_SUCCESS:
    'SHOW_TEMPLATE_LINK_GALLERY_MODAL_SUCCESS',

  LINK_MOVIE_TO_GALLERY_REQUEST: 'LINK_MOVIE_TO_GALLERY_REQUEST',
  LINK_MOVIE_TO_GALLERY_SUCCESS: 'LINK_MOVIE_TO_GALLERY_SUCCESS',
  LINK_MOVIE_TO_GALLERY_FAILURE: 'LINK_MOVIE_TO_GALLERY_FAILURE',

  STAR_DELETE_AUDIO_FILE_REQUEST: 'STAR_DELETE_AUDIO_FILE_REQUEST',
  STAR_DELETE_AUDIO_FILE_SUCCESS: 'STAR_DELETE_AUDIO_FILE_SUCCESS',
  STAR_DELETE_AUDIO_FILE_FAILURE: 'STAR_DELETE_AUDIO_FILE_FAILURE',
  STAR_UPLOAD_AUDIO_IMAGE_REQUEST: 'STAR_UPLOAD_AUDIO_IMAGE_REQUEST',
  STAR_UPLOAD_AUDIO_IMAGE_SUCCESS: 'STAR_UPLOAD_AUDIO_IMAGE_SUCCESS',
  STAR_UPLOAD_AUDIO_IMAGE_FAILURE: 'STAR_UPLOAD_AUDIO_IMAGE_FAILURE',

  STAR_EDIT_AUDIO_TITLE_REQUEST: 'STAR_EDIT_AUDIO_TITLE_REQUEST',
  STAR_EDIT_AUDIO_TITLE_SUCCESS: 'STAR_EDIT_AUDIO_TITLE_SUCCESS',
  STAR_EDIT_AUDIO_TITLE_FAILURE: 'STAR_EDIT_AUDIO_TITLE_FAILURE',

  STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_REQUEST:
    'STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_REQUEST',
  STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_SUCCESS:
    'STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_SUCCESS',
  STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_FAILURE:
    'STAR_GET_TEMPLATES_FOR_AUDIO_CLIP_FAILURE',

  STAR_DELETE_TEMPLATE_IN_AUDIO_REQUEST:
    'STAR_DELETE_TEMPLATE_IN_AUDIO_REQUEST',
  STAR_DELETE_TEMPLATE_IN_AUDIO_SUCCESS:
    'STAR_DELETE_TEMPLATE_IN_AUDIO_SUCCESS',
  STAR_DELETE_TEMPLATE_IN_AUDIO_FAILURE:
    'STAR_DELETE_TEMPLATE_IN_AUDIO_FAILURE',

  SHOW_ADD_COSMOS_VIDEO_MODAL: 'SHOW_ADD_COSMOS_VIDEO_MODAL',
  HIDE_ADD_COSMOS_VIDEO_MODAL: 'HIDE_ADD_COSMOS_VIDEO_MODAL',

  STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_REQUEST:
    'STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_REQUEST',
  STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_SUCCESS:
    'STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_SUCCESS',
  STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_FAILURE:
    'STAR_GET_LANGUAGES_FOR_AUDIO_CLIP_FAILURE',

  STAR_UPLOAD_PREMIUM_VIDEO_REQUEST: 'STAR_UPLOAD_PREMIUM_VIDEO_REQUEST',
  STAR_UPLOAD_PREMIUM_VIDEO_SUCCESS: 'STAR_UPLOAD_PREMIUM_VIDEO_SUCCESS',
  STAR_UPLOAD_PREMIUM_VIDEO_FAILURE: 'STAR_UPLOAD_PREMIUM_VIDEO_FAILURE',

  STAR_EDIT_PREMIUM_VIDEO_CLIP_REQUEST: 'STAR_EDIT_PREMIUM_VIDEO_CLIP_REQUEST',
  STAR_EDIT_PREMIUM_VIDEO_CLIP_SUCCESS: 'STAR_EDIT_PREMIUM_VIDEO_CLIP_SUCCESS',
  STAR_EDIT_PREMIUM_VIDEO_CLIP_FAILURE: 'STAR_EDIT_PREMIUM_VIDEO_CLIP_FAILURE',

  STAR_UPDATE_VIDEOS_REQUEST: 'STAR_UPDATE_VIDEOS_REQUEST',
  STAR_UPDATE_VIDEOS_SUCCESS: 'STAR_UPDATE_VIDEOS_SUCCESS',
  STAR_UPDATE_VIDEOS_FAILURE: 'STAR_UPDATE_VIDEOS_FAILURE',

  STAR_GET_SUBSCRIPTION_PLAN_REQUEST: 'STAR_GET_SUBSCRIPTION_PLAN_REQUEST',
  STAR_GET_SUBSCRIPTION_PLAN_SUCCESS: 'STAR_GET_SUBSCRIPTION_PLAN_SUCCESS',
  STAR_GET_SUBSCRIPTION_PLAN_FAILURE: 'STAR_GET_SUBSCRIPTION_PLAN_FAILURE',

  STAR_ADD_SUBSCRIPTION_PLAN_REQUEST: 'STAR_ADD_SUBSCRIPTION_PLAN_REQUEST',
  STAR_ADD_SUBSCRIPTION_PLAN_SUCCESS: 'STAR_ADD_SUBSCRIPTION_PLAN_SUCCESS',
  STAR_ADD_SUBSCRIPTION_PLAN_FAILURE: 'STAR_ADD_SUBSCRIPTION_PLAN_FAILURE',

  STAR_DELETE_SUBSCRIPTION_PLAN_REQUEST:
    'STAR_DELETE_SUBSCRIPTION_PLAN_REQUEST',
  STAR_DELETE_SUBSCRIPTION_PLAN_SUCCESS:
    'STAR_DELETE_SUBSCRIPTION_PLAN_SUCCESS',
  STAR_DELETE_SUBSCRIPTION_PLAN_FAILURE:
    'STAR_DELETE_SUBSCRIPTION_PLAN_FAILURE',
  STAR_ADD_NEWS_REQUEST: 'STAR_ADD_NEWS_REQUEST',
  STAR_ADD_NEWS_SUCCESS: 'STAR_ADD_NEWS_SUCCESS',
  STAR_ADD_NEWS_EXISTS: 'STAR_ADD_NEWS_EXISTS',
  STAR_ADD_NEWS_FAILURE: 'STAR_ADD_NEWS_FAILURE',
  STAR_UPDATE_NEWS_STATUS_REQUEST: 'STAR_UPDATE_NEWS_STATUS_REQUEST',
  STAR_UPDATE_NEWS_STATUS_SUCCESS: 'STAR_UPDATE_NEWS_STATUS_SUCCESS',
  STAR_UPDATE_NEWS_STATUS_FAILURE: 'STAR_UPDATE_NEWS_STATUS_FAILURE',

  STAR_DELETE_NEWS_REQUEST: 'STAR_DELETE_NEWS_REQUEST',
  STAR_DELETE_NEWS_SUCCESS: 'STAR_DELETE_NEWS_SUCCESS',
  STAR_DELETE_NEWS_FAILURE: 'STAR_DELETE_NEWS_FAILURE',

  STAR_UPDATE_GALLERY_IMAGE_REQUEST: 'STAR_UPDATE_GALLERY_IMAGE_REQUEST',
  STAR_UPDATE_GALLERY_IMAGE_SUCCESS: 'STAR_UPDATE_GALLERY_IMAGE_SUCCESS',
  STAR_UPDATE_GALLERY_IMAGE_FAILURE: 'STAR_UPDATE_GALLERY_IMAGE_FAILURE',

  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',

  CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILURE: 'CREATE_EVENT_FAILURE',

  GET_EVENT_TYPES_REQUEST: 'GET_EVENT_TYPES_REQUEST',
  GET_EVENT_TYPES_SUCCESS: 'GET_EVENT_TYPES_SUCCESS',
  GET_EVENT_TYPES_FAILURE: 'GET_EVENT_TYPES_FAILURE',

  GET_PERK_TYPES_REQUEST: 'GET_PERK_TYPES_REQUEST',
  GET_PERK_TYPES_SUCCESS: 'GET_PERK_TYPES_SUCCESS',
  GET_PERK_TYPES_FAILURE: 'GET_PERK_TYPES_FAILURE',

  STAR_PASSWORD_RESET_REQUEST: 'STAR_PASSWORD_RESET_REQUEST',
  STAR_PASSWORD_RESET_SUCCESS: 'STAR_PASSWORD_RESET_SUCCESS',
  STAR_PASSWORD_RESET_FAILURE: 'STAR_PASSWORD_RESET_FAILURE',

  GET_STAR_KYC_REQUEST: 'GET_STAR_KYC_REQUEST',
  GET_STAR_KYC_SUCCESS: 'GET_STAR_KYC_SUCCESS',
  GET_STAR_KYC_FAILURE: 'GET_STAR_KYC_FAILURE',

  GET_STAR_KYC_DOC_SIGNED_URL_REQUEST: 'GET_STAR_KYC_DOC_SIGNED_URL_REQUEST',
  GET_STAR_KYC_DOC_SIGNED_URL_SUCCESS: 'GET_STAR_KYC_DOC_SIGNED_URL_SUCCESS',
  GET_STAR_KYC_DOC_SIGNED_URL_FAILURE: 'GET_STAR_KYC_DOC_SIGNED_URL_FAILURE',

  UPDATE_STAR_KYC_REQUEST: 'UPDATE_STAR_KYC_REQUEST',
  UPDATE_STAR_KYC_SUCCESS: 'UPDATE_STAR_KYC_SUCCESS',
  UPDATE_STAR_KYC_FAILURE: 'UPDATE_STAR_KYC_FAILURE',

  GET_STAR_BANK_DETAILS_REQUEST: 'GET_STAR_BANK_DETAILS_REQUEST',
  GET_STAR_BANK_DETAILS_SUCCESS: 'GET_STAR_BANK_DETAILS_SUCCESS',
  GET_STAR_BANK_DETAILS_FAILURE: 'GET_STAR_BANK_DETAILS_FAILURE',

  UPDATE_STAR_BANK_DETAILS_REQUEST: 'UPDATE_STAR_BANK_DETAILS_REQUEST',
  UPDATE_STAR_BANK_DETAILS_SUCCESS: 'UPDATE_STAR_BANK_DETAILS_SUCCESS',
  UPDATE_STAR_BANK_DETAILS_FAILURE: 'UPDATE_STAR_BANK_DETAILS_FAILURE',

  UPDATE_STAR_EVENT_REQUEST: 'UPDATE_STAR_EVENT_REQUEST',
  UPDATE_STAR_EVENT_SUCCESS: 'UPDATE_STAR_EVENT_SUCCESS',
  UPDATE_STAR_EVENT_FAILURE: 'UPDATE_STAR_EVENT_FAILURE',

  DELETE_STAR_EVENT_REQUEST: 'DELETE_STAR_EVENT_REQUEST',
  DELETE_STAR_EVENT_SUCCESS: 'DELETE_STAR_EVENT_SUCCESS',
  DELETE_STAR_EVENT_FAILURE: 'DELETE_STAR_EVENT_FAILURE',

  GET_STAR_USER_INFO_REQUEST: 'GET_STAR_USER_INFO_REQUEST',
  GET_STAR_USER_INFO_SUCCESS: 'GET_STAR_USER_INFO_SUCCESS',
  GET_STAR_USER_INFO_FAILURE: 'GET_STAR_USER_INFO_FAILURE',
};
