import {chatConstants} from '../constants';
const initialState = {
  blocked_users: {
    loading: null,
    data: {
      content: [],
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 1,
    },
  },
  followers: {
    loading: null,
    data: {
      content: [],
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 1,
    },
  },
  user_by_star_id: {
    loading: null,
    data: {
      id: null,
      name: null,
      username: null,
      profileImageUrl: '',
      following: false,
      statusUpdateTimestamp: null,
    },
  },
};

export function chat(state = initialState, action) {
  switch (action.type) {
    case chatConstants.GET_BLOCKED_USERS_REQUEST:
      return {
        ...state,
        blocked_users: {
          ...state.blocked_users,
          loading: true,
        },
      };
    case chatConstants.GET_BLOCKED_USERS_SUCCESS:
      return {
        ...state,
        blocked_users: {
          ...state.blocked_users,
          data: {
            ...state.blocked_users.data,
            content: action.data,
          },
          loading: false,
        },
      };
    case chatConstants.GET_BLOCKED_USERS_FAILURE:
      return {
        ...state,
        blocked_users: {
          ...state.blocked_users,
          loading: false,
        },
      };

    case chatConstants.GET_FOLLOWERS_REQUEST:
      return {
        ...state,
        followers: {
          ...state.followers,
          loading: true,
        },
      };
    case chatConstants.GET_FOLLOWERS_SUCCESS:
      return {
        ...state,
        followers: {
          ...state.followers,
          data: action.data,
          loading: false,
        },
      };
    case chatConstants.GET_FOLLOWERS_FAILURE:
      return {
        ...state,
        followers: {
          ...state.followers,
          loading: false,
        },
      };

    case chatConstants.USER_BY_STAR_ID_REQUEST:
      return {
        ...state,
        user_by_star_id: {
          ...state.user_by_star_id,
          loading: true,
        },
      };
    case chatConstants.USER_BY_STAR_ID_SUCCESS:
      return {
        ...state,
        user_by_star_id: {
          ...state.user_by_star_id,
          data: action.data,
          loading: false,
        },
      };
    case chatConstants.USER_BY_STAR_ID_FAILURE:
      return {
        ...state,
        user_by_star_id: {
          ...state.user_by_star_id,
          loading: false,
        },
      };
    default:
      return state;
  }
}
