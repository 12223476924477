import React from 'react';

import './index.css';

const Loader = ({ numberOfDots = 5, className, text, primary }) => (
  <div className={`loading ${primary ? 'primary' : ''} ${className}`}>
    {
      text && (
        <div className={`mt-1 text-base font-medium ${primary ? 'text-primary-500 dark:text-primary-50' : 'text-gray-500 dark:text-gray-50' }`}>
          {text}
        </div>
      )
    }

    <div className='dots'>
      {
        new Array(numberOfDots).fill(null).map((_, index) => (
          <div className="dot" key={index} />
        ))
      }
    </div>
  </div>
);

export default Loader;
