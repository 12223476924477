export const FS_CHAT_COLLECTION = 'chat';
export const FS_CHAT_BLOCK_COLLECTION = 'chat_block';
export const FS_CHAT_SNAPSHOT_COLLECTION = 'chat_snapshot';
export const FS_CHAT_CONTACTS_COLLECTION = 'chat_contacts';
export const FS_CHAT_REQUESTS_COLLECTION = 'chat_requests';
export const FS_USER_STATUS_COLLECTION = 'user_status';

export const CHAT_EMOJIS = {
  grinningFace: '😀',
  beamingFace: '😁',
  smilingFaceHalo: '😇',
  grinningFaceSweat: '😅',
  faceWithTearsJoy: '😂',
  grinningSquintingFace: '😆',
  pizza: '🍕',
  rocket: '🚀',
  thumbsUp: '👍',
  faceWithRollingEyes: '🙄',
  ghost: '👻',
  heart: '❤️',
  seeNoEvilMonkey: '🙈',
  lion: '🦁',
  VictoryHand: '✌️',
  SmilingFaceWithSunglasses: '😎',
  grinningFaceSmilingEyes: '😄',
  smilingFaceWithHeartEyes: '😍',
  monkeyFace: '🐵',
  smirkingFace: '😏',
  winkingFace: '😉',
  music: '🎶',
  winkingFaceWithTongue: '😜',
  faceWithSpectacles: '🤓',
  hearNoEvilMonkey: '🙉',
  disappointedFace: '😌',
  eyes: '👀',
  yummyFace: '😋',
  talkNoEvilMonkey: '🙊',
  tiredFace: '😩',
  astonishedFace: '😳',
  plow: '⛏',
  cake: '🍰',
  panWithOmlette: '🍳',
};

export const chatConstants = {
	GET_BLOCKED_USERS_REQUEST: "GET_BLOCKED_USERS_REQUEST",
	GET_BLOCKED_USERS_SUCCESS: "GET_BLOCKED_USERS_SUCCESS",
	GET_BLOCKED_USERS_FAILURE: "GET_BLOCKED_USERS_FAILURE",

	GET_FOLLOWERS_REQUEST: "GET_FOLLOWERS_REQUEST",
	GET_FOLLOWERS_SUCCESS: "GET_FOLLOWERS_SUCCESS",
	GET_FOLLOWERS_FAILURE: "GET_BLOCKED_USERS_FAILURE",

	USER_BY_STAR_ID_REQUEST: 'USER_BY_STAR_ID_REQUEST',
	USER_BY_STAR_ID_SUCCESS: 'USER_BY_STAR_ID_SUCCESS',
	USER_BY_STAR_ID_FAILURE: 'USER_BY_STAR_ID_FAILURE',
};
