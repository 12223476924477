import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => (
  <section className="flex items-center justify-center w-full h-full p-16 dark:bg-gray-900 dark:text-gray-100">
    <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
      <div className="max-w-md text-center">
        <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600 app-gradient-text">
          <span className="sr-only">Error</span>404
        </h2>
        <p className="text-2xl font-semibold md:text-3xl">Sorry, we couldn't find this page.</p>
        <br />
        <Link to="/" className="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">
          Back to app
        </Link>
      </div>
    </div>
  </section>
);

export default PageNotFound;
