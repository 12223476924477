const second = 1000; // ms
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;
const week = 7 * day;
const month = 4 * week;
const year = 12 * month;

const makeTwoDigits = (number) => `00${number.toString()}`.slice(-2);

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const toMonthText = (number) => months[number];
const toDayText = (number) => days[number];

export const formatDateTime = (
  dateTimeString,
  format = 'mm/dd/yy hh:MMp',
  utc = false,
) => {
  let dateObj = new Date(dateTimeString);
  let hours;
  let month;
  let dateObjInUTC;

  if (utc) {
    dateObjInUTC = new Date(
      dateObj.getTime() + dateObj.getTimezoneOffset() * 60000,
    );
    hours = dateObjInUTC.getHours();
    month = dateObjInUTC.getMonth();
  } else {
    hours = dateObj.getHours();
    month = dateObj.getMonth();
  }

  const dateObject = utc ? dateObjInUTC : dateObj;

  return format
    .replace('p', hours >= 12 ? 'pm' : 'am')
    .replace('P', hours >= 12 ? 'PM' : 'AM')
    .replace('mmm', toMonthText(month))
    .replace('mm', makeTwoDigits(month + 1))
    .replace('ddd', toDayText(dateObject.getDay()))
    .replace('dd', makeTwoDigits(dateObject.getDate()))
    .replace('yyyy', dateObject.getFullYear())
    .replace('yy', makeTwoDigits(dateObject.getFullYear()))
    .replace('hh', makeTwoDigits(hours <= 12 ? hours : hours % 12))
    .replace('HH', makeTwoDigits(hours))
    .replace('MM', makeTwoDigits(dateObject.getMinutes()))
    .replace('ss', makeTwoDigits(dateObject.getSeconds()));
};

export const isYesterday = (date) => {
  if (!(date instanceof Date)) {
    throw new Error('Invalid argument: you must provide a "date" instance');
  }

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
}

export const isToday = (date) => {
  if (!(date instanceof Date)) {
    throw new Error('Invalid argument: you must provide a "date" instance');
  }

  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export const capitalize = (s) => {
  if (s.length < 2) {
    return s;
  }
  const t = s.toLowerCase();
  return t[0].toUpperCase() + t.slice(1);
};

export const getInitialsFromName = (name) => {
  const nameOne = name.toString().trim();
  const array = nameOne.split(' ');
  const result = array.reduce((acc, cur) => {
    return acc + cur.charAt(0);
  }, '');
  return result;
};

export const getTimeElapsed = (time) => {
  if (!time) return '';
  const now = new Date();
  const timeElapsed = now - new Date(time);
  return isNaN(timeElapsed) ? now - jsCoreDateCreator(time) : timeElapsed;
};

const jsCoreDateCreator = (dateString) => {
  // new Date() doesn't work as it does in NodeJS
  // dateString *HAS* to be in this format "YYYY-MM-DD HH:MM:SS"
  const dateParam = dateString.split(/[\s-:]/);
  dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString();
  return new Date(...dateParam);
};

export const getTimeElapsedMessage = (short = false, timeElapsed, date) => {
  if (!timeElapsed) return '';

  // less than a minute
  if (timeElapsed < minute) return 'A few seconds ago';

  // less than an hour
  if (timeElapsed < hour) {
    const elapsedMinutes = ~~(timeElapsed / minute);
    return `${elapsedMinutes} min ago`;
  }

  // less than a day
  if (timeElapsed < day) {
    const elapsedHours = ~~(timeElapsed / hour);
    return `${elapsedHours} ${
      short ? 'h' : elapsedHours === 1 ? 'hour ago' : 'hours ago'
    }`;
  }

  // yesterday
  if (timeElapsed > day && timeElapsed < day * 2) return 'Yesterday';

  // less than a week
  if (timeElapsed < week) {
    const elapsedDays = Math.round(timeElapsed / day);
    return `${elapsedDays} ${short ? 'D' : 'days ago'}`;
  }

  // less than a month
  if (timeElapsed < month) {
    const elapsedWeeks = ~~(timeElapsed / week);
    return `${elapsedWeeks} ${
      short ? 'W' : elapsedWeeks === 1 ? 'week ago' : 'weeks ago'
    }`;
  }

  // less than a year
  if (timeElapsed < year) {
    const elapsedMonths = ~~(timeElapsed / month);
    return `${elapsedMonths} ${
      short ? 'M' : elapsedMonths === 1 ? 'month ago' : 'months ago'
    }`;
  }

  return formatDateTime(date, 'dd mmm yyyy');
};

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};

export const profileImageUrl = (userId) =>
  `https://dev-cosmos-img-user.ams3.cdn.digitaloceanspaces.com/user/${userId}-profile.jpg`;
  
export const getErrorFromResponse = (response) => {
  const {data, reason} = response.data || {};
  const message = data || reason || 'Something went wrong';
  return message;
};

export const getPlanExpiryText = (planType) => {
  const daysMapper = {
    DAILY: 1,
    WEEKLY: 7,
    MONTHLY: 30,
    QUARTERLY: 90,
    YEARLY: 365,
  };
  const days = daysMapper[planType];

  return `${days} ${days === 1 ? 'day' : 'days'}`;
};

export const isPast = (date) => new Date(date) < new Date();

export const getName = (firstName, lastName) => (
  `${firstName}${lastName ? ' ' : ''}${lastName}`
);

export const isDarkMode = () => {
  return localStorage.getItem('theme') === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
};

export const composeConversationId = (id1, id2) => (
  [parseInt(id1), parseInt(id2)].sort((a, b) => a - b).join('_')
);
